import { useEffect } from 'react';
import './Header.css';
import us from '../../img/us.png';
import date from '../../img/date.png';
import weddingTxt from '../../img/wedding_txt.png';


function Header() {

  useEffect(() => {
  }, [])

  return (
    <div className="Header">
      <div className='topLine'>
        <div className='left'></div>
        <div className='center'></div>
        <div className='right'><a className='rsvpButton' href='#infos'>Infos & RSVP</a></div>
      </div>
      <div className='usContainer'>
        <img className='us' src={us} />
      </div>
    </div>
  );
}

export default Header;
