import { useEffect } from 'react';
import './AddButton.css';

function AddButton() {

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.addevent.com/libs/atc/1.6.1/atc.min.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }, [])

  return (
    <div className="AddButton">
      <a title="Add to Calendar" class="addeventatc" data-id="hY16563031" href="https://www.addevent.com/event/hY16563031" target="_blank">Add to Calendar</a>
    </div>
  );
}

export default AddButton;
