import { useEffect } from 'react';
import './App.css';
import AddButton from './component/AddButton/AddButton';
import Header from './component/Header/Header';
import Infos from './component/Infos/Infos';
import Rsvp from './component/Rsvp/Rsvp';

function App() {

  useEffect(() => {
  }, [])

  return (
    <div className="App">
      <Header />
      <Infos />
      <Rsvp />
    </div>
  );
}

export default App;
