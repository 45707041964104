import { useEffect } from 'react';
import './Infos.css';


function Infos() {

  useEffect(() => {
  }, [])

  return (
    <div id='infos' className="Infos">
      <div className="title">On vous en dit plus</div>
      <div className="content">
        <div className='dates subbloc'>
          <a href="https://evt.to/aoiogmgaw" target="_blank">RDV du <span className="emphasize">Samedi 23 Septembre</span> - 14h<br />
          au <span className="emphasize">Dimanche 24 Septembre</span> - 14h</a>
        </div>
        <div className='location subbloc'>
          <div className="subtitle">Où ?</div>
          <div className='subcontent'>
            <a href="https://goo.gl/maps/t5kAB1tvJAfYa57P9" target="_blank"><span className="emphasize">L’Escampette</span> (<span className="underline">Le Camp, 44390 Saffré</span>)</a>
          </div>
        </div>
        <div className='program subbloc'>
          <div className="subtitle">Quoi ?</div>
          <div className='subontent'>
            <ul>
              <li>L’après-midi : Buffet, cérémonie, jeux...</li>
              <li>Soirée : dîner & concert !</li>
              <li>Lendemain matin : brunch</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Infos;
