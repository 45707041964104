import { useEffect, useState } from 'react';
import './Rsvp.css';


function Rsvp() {

  const [name, setName] = useState("");
  const [response, setResponse] = useState(true);
  const [countAdults, setCountAdults] = useState(1);
  const [countChildren, setCountChildren] = useState(0);
  const [hosted, setHosted] = useState(true);
  const [comments, setComments] = useState("");
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
  }, [])

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = {
      "name": name,
      "response": response,
      "adults": countAdults,
      "children": countChildren,
      "hosting": hosted,
      "comments": comments
    };

    fetch("https://getform.io/f/9e7982a1-5ccb-4f9a-b558-ca2c54a73a2d", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "accept": "application/json"
      },
      body: JSON.stringify(formData),
    })
      .then(async response => {
        try {
          const result = await response.json();
          if (result.success === true) {
            setSent(true);
            setLoading(false);
          } else {
            setError(true);
            setLoading(false);
          }
        } catch (e) {
          setError(true);
          setLoading(false);
        }
      })
      .catch(error => {
        setError(true);
        setLoading(false);
      })
  }

  const onClear = () => {
    setName("");
    setResponse(true);
    setCountAdults(1);
    setCountChildren(0);
    setHosted(true);
    setComments("");
    setSent(false);
  }



  return (
    <div className={'Rsvp' + (loading ? ' loading' : sent ? ' sent' : error ? ' error' : '')}>
      <div className='title'>Dîtes-nous tout !</div>
      <div className='content'>
        <div className='input iam'>
          <div className='label'>Je suis</div>
          <input type="text" autocomplete="name" name="name" placeholder='Nom Prénom' value={name} onChange={(event) => setName(event.target.value)} />
        </div>

        <div className='input response'>
          <div onClick={() => setResponse(true)} className={'item' + (response ? ' selected' : '')}>
            <div>Je serai là</div>
          </div>
          <div onClick={() => setResponse(false)} className={'item' + (response ? '' : ' selected')}>
            <div>Je ne pourrai pas</div>
          </div>
        </div>
        {response &&
          <>
            <div className='input count'>
              <div className='label'>Nous serons</div>
              <div className='subcontent'>
                <div className='item'>
                  <input type="number" name="adults" value={countAdults} onChange={(event) => setCountAdults(event.target.value)} />
                  <div className='side'>Adulte(s)</div>
                </div>
                <div className='item'>
                  <input type="number" name="children" value={countChildren} onChange={(event) => setCountChildren(event.target.value)} />
                  <div className='side'>Enfant(s)</div>
                </div>
              </div>
            </div>

            <div className='input hosting'>
              <div onClick={() => setHosted(true)} className={'item' + (hosted ? ' selected' : '')}>
                <div className='title'>Je dormirai bien sur place</div>
                <div className='subtitle'>En grandes tentes cosy avec vrais matelas. Nombre de places limitées, on vous confirmera la disponibilité !</div>
              </div>
              <div onClick={() => setHosted(false)} className={'item' + (hosted ? '' : ' selected')}>
                <div className='title'>Je me charge de mon hébergement</div>
                <div className='subtitle'>Nous vous enverrons les adresses les plus proches ;)</div>
              </div>
              <div className='ps'>
                PS : un espace camping sauvage est dispo pour les plus téméraires !
              </div>
            </div>
          </>
        }

        <div className='input comments'>
          <div className='label'>Votre ultime bafouille</div>
          <textarea rows="5" name="more" placeholder='Demandes / Restrictions alimentaires ?' value={comments} onChange={(event) => setComments(event.target.value)} />
        </div>

        <input type="submit" onClick={(e) => onSubmit(e)} className='submit' value="C'est tout bon !" />
      </div>
      <div className='overlay loader'>
        <div className='backDrop'></div>
        <div className='inner'></div>
      </div>
      <div className='overlay sentResponse'>
        <div onClick={() => onClear()} className='backDrop'></div>
        <div className='inner content'>
          <div onClick={() => setSent(false)} className='close'></div>
          <div className='title'>Merci pour votre réponse !</div>
          <div className='message'>
            Nous revenons rapidement vers vous avec encore plus d'informations sur l'événement.
            <br />
            En attendant n'hésitez pas à nous contacter directement pour toute question ou changement.
          </div>
          <div className='mails'>
            <a href="mailto:celineetnico2309@gmail.com">celineetnico2309@gmail.com</a>
          </div>
        </div>
      </div>
      <div className='overlay errorContent'>
        <div onClick={() => setError(false)} className='backDrop'></div>
        <div className='inner content'>
          <div onClick={() => setError(false)} className='close'></div>
          <div className='title'>Oups !?!</div>
          <div className='message'>
            Une erreur s'est produite...
            <br />
            Veuillez réessayer et/ou nous écrire directement à l'adresse ci-dessous.
          </div>
          <div className='mails'>
            <a href="mailto:celineetnico2309@gmail.com">celineetnico2309@gmail.com</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Rsvp;
